import React, { useContext } from "react"
import { makeStyles } from "@material-ui/styles"
import { getScaleColor } from "../nutrientScales"
import { Chip } from "@material-ui/core"
import { ScreenContext } from "../../../../../context/screenContext";

const useStyles = makeStyles({
  cell: ({ colorA, colorB, cellWidth }) => ({
    display: "flex",
    background: `linear-gradient(to bottom right,${colorA} 50%,${colorB} 50%)`,
    height: `${cellWidth}px`,
    width: `${cellWidth}px`,
    margin: "2px",
    borderRadius: "4px",
    alignItems: "center",
  }),
  chip: {
    margin: "auto",
    opacity: "0.9",
    backgroundColor: "white",
  },
})

const MatrixCell = ({
  scaleTypeA,
  levelA,
  scaleTypeB,
  levelB,
  numResults,
  onClick,
  selected,
}) => {

  const screen = useContext(ScreenContext)
  const cellWidth = screen.mobile? 40: 48

  const opacity = "66"
  const colorA = getScaleColor(scaleTypeA, levelA) + opacity
  const colorB = getScaleColor(scaleTypeB, levelB) + opacity
  const classes = useStyles({ colorA: colorA, colorB: colorB, cellWidth: cellWidth })
  return (
    <div className={classes.cell}>
      {numResults > 0 ? (
        <Chip
          onClick={event => onClick(levelA, levelB)}
          className={classes.chip}
          style={{
            backgroundColor: selected ? "rgb(100,100,100)" : "white",
            color: selected ? "white" : "black",
          }}
          label={numResults}
        />
      ) : null}
    </div>
  )
}

export default MatrixCell
