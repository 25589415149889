import React from "react"
import {
  Paper,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Grid,
} from "@material-ui/core"
import DetailTable from "./nutrientTable/detailTable"
import { makeStyles } from "@material-ui/styles"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import RenderMessages from "./nutrientTable/messages/renderMessages"
import Image from "gatsby-image"

const useStyles = makeStyles({
  caption: {
    flexGrow: 1,
    marginRight: "20px",
  },
  foodDetail: {
    padding: "20px",
  },
  foodImage: {
    borderRadius: "10px",
  },
})

const FoodSlider = ({
  pageCount,
  activePage,
  item,
  photo,
  moveBack,
  moveForward,
}) => {
  const classes = useStyles()

  return (
    <Paper>
      <Toolbar variant="dense">
        <IconButton
          aria-label="back"
          disableRipple
          disabled={activePage === 1}
          onClick={moveBack}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" align="center" className={classes.caption}>
          {item.foodEN} -{" "}
          <span style={{ color: "gray" }}>
            {activePage} of {pageCount}
          </span>
        </Typography>
        <IconButton
          aria-label="forward"
          disableRipple
          disabled={activePage === pageCount}
          onClick={moveForward}
        >
          <ArrowForwardIcon />
        </IconButton>
      </Toolbar>
      <Container>
        <RenderMessages item={item} />
        <Grid container spacing={4} className={classes.foodDetail}>
          <Grid item xs={5}>
            {photo ? (
              <Image fluid={photo} className={classes.foodImage} />
            ) : null}
          </Grid>
          <Grid item xs={7}>
            <DetailTable data={item} />
          </Grid>
        </Grid>
      </Container>
    </Paper>
  )
}

export default FoodSlider
