import React, { useContext } from "react"
import MatrixCell from "./renderCell"
import { makeStyles } from "@material-ui/styles"
import RenderLevel from "../renderLevel"
import nutrientsMeta from "../nutrientsMeta"
import getScaleType from "../nutrientScales"
import { ScreenContext } from "../../../../../context/screenContext";

const useStyles = makeStyles({
  container: {
    display: "grid",
    gap: "10px 10px",
    gridTemplateColumns: `30px 360px`,
    gridTemplateRows: "auto 50px",
    gridTemplateAreas: '"nutrientA main" ". nutrientB"',
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  matrix: {
    gridArea: "main",
    display: "flex",
    flexDirection: "column",
  },
  scaleLabelsA: {
    width: "40px",
  },
  scaleLabelsB: ({paddingScaleB}) => ({
    width: "50px",
    marginTop: "10px",
    paddingLeft: `${paddingScaleB}px`,
  }),
  labelA: {
    gridArea: "nutrientA",
    writingMode: "vertical-lr",
    transform: "rotate(180deg)",
    textAlign: "center",
  },
  labelB: ({widthScaleB}) => ({
    width: `${widthScaleB}px`,
    gridArea: "nutrientB",
    textAlign: "center",
  }),
})

const RenderMatrix = ({
  counts,
  stage,
  nutrientA,
  nutrientB,
  onSelectCell,
  selectedLevelA,
  selectedLevelB,
}) => {

  const screen = useContext(ScreenContext)
  const widthScaleB = screen.mobile? 300: 360
  const paddingScaleB = screen.mobile? 0: 20

  const classes = useStyles({widthScaleB: widthScaleB, paddingScaleB: paddingScaleB})

  const scaleTypeA = getScaleType(nutrientA, stage)
  const scaleTypeB = getScaleType(nutrientB, stage)

  return (
    <div className={classes.container}>
      <div className={classes.labelA}>{nutrientsMeta[nutrientA].label}</div>
      <div className={classes.matrix}>
        {[5, 4, 3, 2, 1].map(levelA => {
          return (
            <div key={levelA} className={classes.row}>
              <div className={classes.scaleLabelsA}>
                <RenderLevel level={levelA} scaleType={scaleTypeA} />
              </div>
              {[1, 2, 3, 4, 5].map(levelB => {
                return (
                  <MatrixCell
                    key={levelB}
                    scaleTypeA={scaleTypeA}
                    levelA={levelA}
                    scaleTypeB={scaleTypeB}
                    levelB={levelB}
                    numResults={counts[[levelA, levelB]]}
                    onClick={onSelectCell}
                    selected={
                      (levelA === selectedLevelA) & (levelB === selectedLevelB)
                    }
                  />
                )
              })}
            </div>
          )
        })}
        <div className={classes.row}>
          <div className={classes.scaleLabelsA}></div>
          {[1, 2, 3, 4, 5].map(levelB => {
            return (
              <div key={levelB} className={classes.scaleLabelsB}>
                <RenderLevel level={levelB} scaleType={scaleTypeB} />
              </div>
            )
          })}
        </div>
      </div>
      <div className={classes.labelB}>{nutrientsMeta[nutrientB].label}</div>
    </div>
  )
}

export default RenderMatrix
