import React, { useContext, useState } from "react"
import { makeStyles } from "@material-ui/styles"
import FoodCheck from "./tool/nutritionTools/foodCheck"
import { ScreenContext } from "../../context/screenContext"
import FoodMatrix from "./tool/nutritionTools/foodMatrix"

const useStyles = makeStyles(theme => ({
  toolsContainer: {
    width: "100%",
    maxWidth: "1800px",
    paddingLeft: "auto",
    paddingBottom: "auto",
    paddingTop: "20px",
  },
  toolsContainerMobile: { paddingBottom: "100px" },
}))

const NutrientsMain = () => {
  const screen = useContext(ScreenContext)
  const [nutrientView, setNutrientView] = useState("list")
  const classes = useStyles()

  return (
    <div
      className={
        screen.tablet ? classes.toolsContainerMobile : classes.toolsContainer
      }
    >
      {nutrientView==="list" ? <FoodCheck setView={setNutrientView}/> : <FoodMatrix setView={setNutrientView}/>}
    </div>
  )
}

export default NutrientsMain
