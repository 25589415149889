import React, { useContext } from "react"
import {
  Container,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import nutrientsMeta from "./nutrientsMeta"
import { FoodMatrixContext } from "../../../../context/foodMatrixContext"

const useStyles = makeStyles({
  container: {
    border: "1px solid lightgray",
    backgroundColor: "rgb(253,253,253)",
  },
  label: {
    margin: "30px 0px 5px 0px",
    color: "gray",
  },
  dropdown: {
    margin: "10px 0px",
  },
  dropdownText: {
    fontSize: "0.9em",
    fontWeight: "bold",
    color: "rgb(150,80,80)",
  },
})

const NutrientPairing = ({ disabled, onChange }) => {
  const classes = useStyles()

  const {
    selectedNutrientA,
    setSelectedNutrientA,
    selectedNutrientB,
    setSelectedNutrientB,
  } = useContext(FoodMatrixContext)

  const allNutrients = Object.keys(nutrientsMeta)

  const onChangeNutrientA = event => {
    setSelectedNutrientA(event.target.value)
    onChange()
  }

  const onChangeNutrientB = event => {
    setSelectedNutrientB(event.target.value)
    onChange()
  }

  const getNutrientsWithout = selected => {
    return allNutrients.filter(n => n !== selected)
  }

  return (
    <>
      <Typography
        align="left"
        display="block"
        variant="caption"
        className={classes.label}
      >
        Select Nutrients to display in the Matrix
      </Typography>
      <Container className={classes.container}>
        <FormGroup>
          <FormControl
            disabled={disabled}
            fullWidth
            className={classes.dropdown}
          >
            <InputLabel id="nutrientA">Nutrient A</InputLabel>
            <Select
              className={classes.dropdownText}
              labelId="nutrientA"
              id="nutrientASelect"
              value={selectedNutrientA}
              onChange={onChangeNutrientA}
            >
              {getNutrientsWithout(selectedNutrientB).map(n => {
                return (
                  <MenuItem className={classes.dropdownText} key={n} value={n}>
                    {nutrientsMeta[n].label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>

          <FormControl
            disabled={disabled}
            fullWidth
            className={classes.dropdown}
          >
            <InputLabel id="nutrientB">Nutrient B</InputLabel>
            <Select
              className={classes.dropdownText}
              labelId="nutrientB"
              id="nutrientBSelect"
              value={selectedNutrientB}
              onChange={onChangeNutrientB}
            >
              {getNutrientsWithout(selectedNutrientA).map(n => {
                return (
                  <MenuItem className={classes.dropdownText} key={n} value={n}>
                    {nutrientsMeta[n].label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </FormGroup>
      </Container>
    </>
  )
}

export default NutrientPairing
