import React, { useContext, useState } from "react"
import FilterListIcon from "@material-ui/icons/FilterList"
import {
  FormGroup,
  FormControl,
  Menu,
  MenuItem,
  Button,
} from "@material-ui/core"
import nutrientsMeta from "./nutrientsMeta"
import { FoodMatrixContext } from "../../../../context/foodMatrixContext"
import { AppContext } from "../../../../context/appContext"
import { makeStyles } from "@material-ui/styles"
import NutrientFilter from "./nutrientFilter"

const useStyles = makeStyles({
  filterHead: {
    marginTop: "30px",
    marginBottom: "10px",
  },
  container: {
    marginBottom: "30px"
  }
})

const NutrientFilterWidget = ({ onChange }) => {
  const { CKDStage } = useContext(AppContext)
  const { activeFilters, setActiveFilters } = useContext(FoodMatrixContext)

  const [anchor, setAnchor] = useState(null)

  const classes = useStyles()

  const getAvailableFilters = () => {
    const all = Object.keys(nutrientsMeta)
    const active = Object.keys(activeFilters)
    return all.filter(nutrient => !active.includes(nutrient))
  }

  const addFilter = nutrient => {
    let active = { ...activeFilters }
    active[nutrient] = [1, 2, 3, 4, 5]
    setActiveFilters(active)
  }

  return (
    <div className={classes.container}>
      <FormGroup row className={classes.filterHead}>
        <FormControl style={{ flexGrow: 2 }}>
          <Button
            disabled={!CKDStage}
            startIcon={<FilterListIcon />}
            variant="contained"
            size="small"
            onClick={event => setAnchor(event.currentTarget)}
          >
            Add Nutrient Filter
          </Button>
          <Menu
            id="filter-menu"
            anchorEl={anchor}
            keepMounted
            open={Boolean(anchor)}
            onClose={() => setAnchor(null)}
          >
            {getAvailableFilters().map(n => {
              return (
                <MenuItem
                  key={n}
                  value={n}
                  onClick={(event, index) => {
                    addFilter(n)
                    setAnchor(null)
                  }}
                >
                  {nutrientsMeta[n].label}
                </MenuItem>
              )
            })}
          </Menu>
        </FormControl>
      </FormGroup>
      {Object.keys(activeFilters).map(nutrient => {
        return (
          <NutrientFilter
            nutrient={nutrient}
            key={nutrient}
            onChange={onChange}
          />
        )
      })}
    </div>
  )
}

export default NutrientFilterWidget
