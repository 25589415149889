import React, { useContext } from "react"
import { Typography, Checkbox, IconButton } from "@material-ui/core"
import nutrientsMeta from "./nutrientsMeta"
import { FoodMatrixContext } from "../../../../context/foodMatrixContext"
import { AppContext } from "../../../../context/appContext"
import { makeStyles } from "@material-ui/styles"
import { getScaleColor, getScaleType } from "./nutrientScales"
import DeleteIcon from "@material-ui/icons/Delete"

const useStyles = makeStyles({
  filterRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  filterLabel: {
    flexGrow: 2,
  },
  deleteIcon: {
    color: "rgb(150,150,150)",
  },
})

const useBoxStyles = makeStyles({
  root: ({ color }) => ({
    color: color,
    padding: "3px",
    "&$checked": {
      color: color,
    },
  }),
})

const NutrientLevelBox = ({ nutrient, level, checked, setChecked }) => {
  const { CKDStage } = useContext(AppContext)
  const color = getScaleColor(getScaleType(nutrient, CKDStage), level)
  const classes = useBoxStyles({ color: color })
  return (
    <Checkbox
      size="small"
      checked={checked}
      onChange={event => setChecked(level, event.target.checked)}
      color="default"
      classes={{ root: classes.root }}
    />
  )
}

const NutrientFilter = ({ nutrient, onChange }) => {
  const classes = useStyles()
  const { activeFilters, setActiveFilters } = useContext(FoodMatrixContext)

  const isChecked = level => {
    const activeLevels = activeFilters[nutrient]
    return activeLevels.includes(level)
  }

  const changeChecked = (level, checked) => {
    const active = { ...activeFilters }
    let activeLevels = activeFilters[nutrient]
    if (activeLevels.includes(level) & !checked) {
      activeLevels = activeLevels.filter(l => l !== level)
    } else if (!activeLevels.includes(level) & checked) {
      activeLevels.push(level)
    }
    active[nutrient] = activeLevels
    setActiveFilters(active)
    onChange()
  }

  const deleteFilter = () => {
    const active = { ...activeFilters }
    delete active[nutrient]
    setActiveFilters(active)
    onChange()
  }

  return (
    <div className={classes.filterRow}>
      <IconButton
        size="small"
        aria-label="delete"
        onClick={() => deleteFilter()}
      >
        <DeleteIcon fontSize="small" className={classes.deleteIcon} />
      </IconButton>
      <Typography variant="body2" className={classes.filterLabel}>
        {nutrientsMeta[nutrient].label}
      </Typography>
      <div className={classes.filterWidget}>
        {[1, 2, 3, 4, 5].map(level => {
          return (
            <NutrientLevelBox
              key={level}
              nutrient={nutrient}
              level={level}
              checked={isChecked(level)}
              setChecked={changeChecked}
            />
          )
        })}
      </div>
    </div>
  )
}

export default NutrientFilter
