import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import NutrientsMain from "../components/tools/nutrientsMain"

const NutrientsPage = () => {
  
  return (
    <Layout activePage="nutrients">
      <SEO title="Nutrients" />
        <NutrientsMain />
    </Layout>
  )
}

export default NutrientsPage
