import React, { useContext, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Toolbar, Paper, Grid, ButtonGroup, Button, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { ScreenContext } from "../../../../context/screenContext"
import { FoodMatrixContext } from "../../../../context/foodMatrixContext"
import CategorySelector from "./categorySelector"
import NutrientPairing from "./nutrientPairing"
import RenderMatrix from "./nutrientMatrix/matrix"
import FoodSlider from "./foodSlider"
import { AppContext } from "../../../../context/appContext"
import NutrientFilterWidget from "./nutrientFilterWidget"
import CKDStageDialog from "../../../ckdStageDialog"
import ReorderIcon from '@material-ui/icons/Reorder';
import GridOnIcon from '@material-ui/icons/GridOn';
import { Skeleton } from "@material-ui/lab";

const queryFoodMatrix = graphql`
  query queryFoodMatrix {
    allStrapiNutrient {
      nodes {
        categoryEN
        foodEN
        vegetarian
        vegan
        caLevel
        energyLevel
        fatLevel
        feLevel
        fibreLevel
        healthLevel
        kLevel
        naLevel
        pLevel
        ppRatioLevel
        proteinLevel
        purineLevel
        vitBLevel
        vitCLevel
      }
    }
    allStrapiNutrientsPhotos {
      nodes {
        foodEN
        image {
          childImageSharp {
            fluid(maxWidth: 300, quality: 25) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    marginBottom: "10px",
  },
  caption: {
    color: "white",
    flexGrow: 1,
    fontSize: "1.1em",
  },
  matrixSwitch: {
    color: "white",
    borderColor: "white",
    padding: "10px"
  },
  tools: {
    padding: "20px 20px 20px 20px"
  },
  placeholder: {
    padding: "20px 40px 40px 40px",
    height: "100%"
  }
}))

const FoodMatrix = ({setView}) => {
  const { CKDStage } = useContext(AppContext)
  const screen = useContext(ScreenContext)
  const {
    selectedCategory,
    setSelectedCategory,
    selectedNutrientA,
    selectedNutrientB,
    activeFilters,
  } = useContext(FoodMatrixContext)

  const [selectedLevelA, setSelectedLevelA] = useState(null)
  const [selectedLevelB, setSelectedLevelB] = useState(null)

  const [resultsPage, setResultsPage] = useState(1)

  const classes = useStyles()
  const {
    allStrapiNutrient: { nodes: foodItems },
    allStrapiNutrientsPhotos: { nodes: foodPhotos },
  } = useStaticQuery(queryFoodMatrix)

  const foodCategories = [...new Set(foodItems.map(item => item.categoryEN))]

  const foodFilter = item => {
    const categoryFilter =
      selectedCategory === "All" ? true : item.categoryEN === selectedCategory

    const nutrientFilter = Object.keys(activeFilters).every(nutrient =>
      activeFilters[nutrient].includes(item[nutrient])
    )
    return categoryFilter & nutrientFilter
  }

  const getPhoto = item => {
    const photoQuery = foodPhotos.filter(
      photoItem => photoItem.foodEN === item.foodEN
    )
    if (photoQuery.length === 0) return null
    const photoItem = photoQuery[0]
    if (!photoItem.image) return null
    return photoItem.image.childImageSharp.fluid
  }

  const getFilteredFoods = () => foodItems.filter(foodFilter)

  const cellClickHandler = (levelA, levelB) => {
    setSelectedLevelA(levelA)
    setSelectedLevelB(levelB)
    setResultsPage(1)
  }

  const getMatrixCounts = data => {
    let counts = {}
    for (let levelA of [1, 2, 3, 4, 5]) {
      for (let levelB of [1, 2, 3, 4, 5]) {
        counts[[levelA, levelB]] = 0
      }
    }
    for (let item of data) {
      counts[[item[selectedNutrientA], item[selectedNutrientB]]] += 1
    }
    return counts
  }

  const getSelectedFoods = () => {
    const selectedItems = getFilteredFoods().filter(item => {
      return (
        (item[selectedNutrientA] === selectedLevelA) &
        (item[selectedNutrientB] === selectedLevelB)
      )
    })
    return selectedItems
  }

  return (
    <>
      <Toolbar variant="dense" className={classes.header}>
        <Typography variant="overline" align="left" className={classes.caption}>
          Nutrients
        </Typography>
        <ButtonGroup size="small" variant="text">
          <Button startIcon={<ReorderIcon/>} className={classes.matrixSwitch} onClick={() => setView("list")}>List</Button>
          <Button startIcon={<GridOnIcon/>} disabled className={classes.matrixSwitch}>Matrix</Button>
        </ButtonGroup>
      </Toolbar>

      {!CKDStage ? <CKDStageDialog open={true} setOpen={() => null} /> : null}

      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <Paper elevation={1} className={classes.tools}>
            <CategorySelector
              fullWidth
              allCategories={foodCategories}
              selectedCategory={selectedCategory}
              onChangeCategory={cat => {
                setSelectedCategory(cat)
                setSelectedLevelA(null)
                setSelectedLevelB(null)
              }}
              disabled={!CKDStage}
            />
            <NutrientPairing
              disabled={!CKDStage}
              onChange={() => {
                setSelectedLevelA(null)
                setSelectedLevelB(null)
              }}
            />
            <NutrientFilterWidget
              onChange={() => {
                setSelectedLevelA(null)
                setSelectedLevelB(null)
              }}
            />
            {CKDStage ? (
              <RenderMatrix
                counts={getMatrixCounts(getFilteredFoods())}
                stage={CKDStage}
                nutrientA={selectedNutrientA}
                nutrientB={selectedNutrientB}
                onSelectCell={cellClickHandler}
                selectedLevelA={selectedLevelA}
                selectedLevelB={selectedLevelB}
              />
            ) : null}
          </Paper>
        </Grid>
      
        <Grid item xs={12} md={7}>
          {getSelectedFoods().length > 0 ? (
            <FoodSlider
              pageCount={getSelectedFoods().length}
              activePage={resultsPage}
              item={getSelectedFoods()[resultsPage - 1]}
              photo={getPhoto(getSelectedFoods()[resultsPage - 1])}
              moveBack={() => setResultsPage(resultsPage - 1)}
              moveForward={() => setResultsPage(resultsPage + 1)}
            />
          ) : 
          <Paper elevation={1} className={classes.placeholder}>
            <Typography 
              align="center" 
              color="textSecondary" 
              variant={screen.mobile? "body1": "h6"}
              style={{marginBottom: "30px"}}
              >
                Select a Matrix Cell to view related Food Items!</Typography>
            <Grid container spacing={7}>
              <Grid item xs={12} md={5}>
                <Skeleton variant="rect" height={250}/>
              </Grid>
              <Grid item xs={12} md={7}>
                {
                [1,2,3,4,5,6,7,8,9,10].map((i) => {
                  return (
                    <Skeleton key={i} animation="wave" style={{marginBottom: "20px"}}/>
                  )
                })}
              </Grid>
            </Grid>
          </Paper>
          }
        </Grid>
      </Grid>
    </>
  )
}

export default FoodMatrix
